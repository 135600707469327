.bs-stepper {
  background-color: #fff;
  border-radius: .5rem;
  box-shadow: 0 4px 24px #22292f1a;
}

.bs-stepper .bs-stepper-header {
  border-bottom: 1px solid #22292f14;
  flex-wrap: wrap;
  margin: 0;
  padding: 1.5rem;
}

.bs-stepper .bs-stepper-header .line {
  color: #6e6b7b;
  background-color: #0000;
  flex: 0;
  min-width: auto;
  min-height: auto;
  margin: 0;
  padding: 0 1.75rem;
  font-size: 1.5rem;
}

.bs-stepper .bs-stepper-header .step {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.bs-stepper .bs-stepper-header .step .step-trigger {
  flex-wrap: nowrap;
  padding: 0;
  font-weight: normal;
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-box {
  color: #babfc7;
  background-color: #babfc71f;
  border-radius: .35rem;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  padding: .5em 0;
  font-weight: 500;
  display: flex;
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
  text-align: left;
  margin: .5rem 0 0 1rem;
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-title {
  display: inherit;
  color: #6e6b7b;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 1rem;
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-subtitle {
  color: #b9b9c3;
  font-size: .85rem;
  font-weight: 400;
}

.bs-stepper .bs-stepper-header .step .step-trigger:hover {
  background-color: #0000;
}

.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-box {
  color: #fff;
  background-color: #7367f0;
  box-shadow: 0 3px 6px #7367f066;
}

.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-label .bs-stepper-title {
  color: #7367f0;
}

.bs-stepper .bs-stepper-header .step.crossed .step-trigger .bs-stepper-box {
  background-color: #7367f01f;
  color: #7367f0 !important;
}

.bs-stepper .bs-stepper-header .step.crossed .step-trigger .bs-stepper-label .bs-stepper-title {
  color: #b9b9c3;
}

.bs-stepper .bs-stepper-header .step.crossed + .line {
  color: #7367f0;
}

.bs-stepper .bs-stepper-content {
  padding: 1.5rem;
}

.bs-stepper .bs-stepper-content .content {
  margin-left: 0;
}

.bs-stepper .bs-stepper-content .content .content-header {
  margin-bottom: 1rem;
}

.bs-stepper.vertical .bs-stepper-header {
  border-bottom: none;
  border-right: 1px solid #ebe9f1;
}

.bs-stepper.vertical .bs-stepper-header .step .step-trigger {
  padding: 1rem 0;
}

.bs-stepper.vertical .bs-stepper-header .line {
  display: none;
}

.bs-stepper.vertical .bs-stepper-content {
  width: 100%;
  padding-top: 2.5rem;
}

.bs-stepper.vertical .bs-stepper-content .content:not(.active) {
  display: none;
}

.bs-stepper.vertical.wizard-icons .step {
  text-align: center;
}

.bs-stepper.wizard-modern {
  box-shadow: none;
  background-color: #0000;
}

.bs-stepper.wizard-modern .bs-stepper-header {
  border: none;
}

.bs-stepper.wizard-modern .bs-stepper-content {
  background-color: #fff;
  border-radius: .5rem;
  box-shadow: 0 4px 24px #22292f1a;
}

.horizontal-wizard, .vertical-wizard, .modern-horizontal-wizard, .modern-vertical-wizard {
  margin-bottom: 2.2rem;
}

.dark-layout .bs-stepper {
  background-color: #283046;
  box-shadow: 0 4px 24px #22292f3d;
}

.dark-layout .bs-stepper .bs-stepper-header {
  border-bottom: 1px solid #3b425314;
}

.dark-layout .bs-stepper .bs-stepper-header .line {
  color: #b4b7bd;
}

.dark-layout .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-box {
  color: #babfc7;
}

.dark-layout .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-title {
  color: #b4b7bd;
}

.dark-layout .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-subtitle {
  color: #676d7d;
}

.dark-layout .bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-box {
  color: #fff;
  background-color: #7367f0;
  box-shadow: 0 3px 6px #7367f066;
}

.dark-layout .bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-label .bs-stepper-title {
  color: #7367f0;
}

.dark-layout .bs-stepper .bs-stepper-header .step.crossed .step-trigger .bs-stepper-label, .dark-layout .bs-stepper .bs-stepper-header .step.crossed .step-trigger .bs-stepper-title {
  color: #676d7d;
}

.dark-layout .bs-stepper.vertical .bs-stepper-header {
  border-right-color: #3b4253;
}

.dark-layout .bs-stepper.wizard-modern {
  box-shadow: none;
  background-color: #0000;
}

.dark-layout .bs-stepper.wizard-modern .bs-stepper-header {
  border: none;
}

.dark-layout .bs-stepper.wizard-modern .bs-stepper-content {
  background-color: #283046;
  box-shadow: 0 4px 24px #22292f3d;
}

html[data-textdirection="rtl"] .btn-prev, html[data-textdirection="rtl"] .btn-next {
  display: flex;
}

html[data-textdirection="rtl"] .btn-prev i, html[data-textdirection="rtl"] .btn-prev svg, html[data-textdirection="rtl"] .btn-next i, html[data-textdirection="rtl"] .btn-next svg {
  transform: rotate(-180deg);
}

@media (width <= 992px) {
  .bs-stepper .bs-stepper-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .bs-stepper .bs-stepper-header .step .step-trigger {
    flex-direction: row;
    padding: .5rem 0 !important;
  }

  .bs-stepper .bs-stepper-header .line {
    display: none;
  }

  .bs-stepper.vertical {
    flex-direction: column;
  }

  .bs-stepper.vertical .bs-stepper-header {
    align-items: flex-start;
  }

  .bs-stepper.vertical .bs-stepper-content {
    padding-top: 1.5rem;
  }
}
/*# sourceMappingURL=index.3183b341.css.map */
